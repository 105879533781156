import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import styled from 'styled-components';

import ContentHeader from 'components/content-header';
import { MAIN_CONTENT_ID } from 'components/skip-to-main/constants';
import { TwoColumn, TwoColumnContent } from 'components/layouts/two-column';
import { OneColumn } from 'components/layouts/one-column';
import { FilterPills, FilterPillsOverflowScrollContainer } from 'components/filter-pills/filter-pills';
import { DesktopOnly, MobileOnly } from 'shared/components';

import DispensaryList from './components/dispensary-list';
import { DispensariesEmptyState } from './components/dispensaries-empty-state';
import { DispensariesLoadingState } from './components/dispensaries-loading-state';
import { DispensariesSort } from './components/dispensaries-sort';
import { FiltersSidebar } from './components/filters-sidebar';

export default function Dispensaries({ dispensaries, dispensariesLoading, filterPillOptions, heading }) {
  const [isSsr, setIsSsr] = useState(true);

  const showDispensariesList = !dispensariesLoading && !_.isEmpty(dispensaries);
  const showEmptyState = !isSsr && !dispensariesLoading && _.isEmpty(dispensaries);

  useEffect(() => {
    setIsSsr(false);
  }, []);

  return (
    <>
      <MobileOnly>
        <FilterPillsOverflowScrollContainer mt={27} mb={-14} ml={0}>
          <FilterPills filterPillOptions={filterPillOptions} />
        </FilterPillsOverflowScrollContainer>
      </MobileOnly>
      <DesktopOnly>
        <StyledTwoColumn pt={70} px={20}>
          <FiltersContainer>
            <FiltersSidebar />
          </FiltersContainer>
          <TwoColumnContent id={MAIN_CONTENT_ID}>
            <ContentHeader heading={heading}>
              <DispensariesSort />
            </ContentHeader>
            {(isSsr || dispensariesLoading) && <DispensariesLoadingState />}
            {showDispensariesList && <DispensaryList dispensaries={dispensaries} />}
            {showEmptyState && <DispensariesEmptyState />}
          </TwoColumnContent>
        </StyledTwoColumn>
      </DesktopOnly>
      <MobileOnly>
        <OneColumn px={25} pt={15}>
          <ContentHeader heading={heading} />
          {dispensariesLoading && <DispensariesLoadingState />}
          {showDispensariesList && <DispensaryList dispensaries={dispensaries} />}
          {showEmptyState && <DispensariesEmptyState />}
        </OneColumn>
      </MobileOnly>
    </>
  );
}

const FiltersContainer = styled.div`
  margin-right: 20px;
`;

const StyledTwoColumn = styled(TwoColumn)`
  .two-column-content {
    padding-top: 68px;
  }
`;

import { BottomDrawerProps } from 'components/core/bottom-drawer';

export type DispensaryListFiltersBottomDrawerProps = Omit<BottomDrawerProps, 'children' | 'heading' | 'subheading'> & {
  onClearFilters: () => void;
  onSave: () => void;
  onSelect: (option: string) => void;
  orderType?: 'delivery' | 'pickup';
  selectedOptions: string[];
};

export type DispensaryListFilterDrawerControllerProps = {
  open: boolean;
  onClose: () => void;
};

export enum PickupFilterOptionValues {
  openNow = 'openNow',
  offerCurbsidePickup = 'offerCurbsidePickup',
  acceptsCreditCards = 'acceptsCreditCards',
  acceptsDutchiePay = 'acceptsDutchiePay',
}

export enum DeliveryFilterOptionValues {
  openNow = 'openNow',
  freeDelivery = 'freeDelivery',
  noMinimum = 'noMinimum',
  acceptsCreditCards = 'acceptsCreditCards',
  acceptsDutchiePay = 'acceptsDutchiePay',
}

export type FilterOptionValues = DeliveryFilterOptionValues | PickupFilterOptionValues;

export type FilterOption = {
  key: string;
  label: string;
  labelTranslationKey: string;
  value: string;
};

import React, { useEffect, useState } from 'react';

import { useDispensariesQueryParams } from 'hooks/use-dispensaries-query-params';
import useSearchPreferences from 'hooks/use-search-preferences';

import { getBooleanFiltersArray } from 'dispensaries/utils';
import { ORDER_TYPE_PICKUP } from 'shared/constants';
import { FiltersSidebar } from './filters-sidebar';

enum SortBy {
  deliveryEstimate = 'deliveryEstimate',
  deliveryFee = 'deliveryFee',
  deliveryMin = 'deliveryMin',
  distance = 'distance',
  pickupTime = 'pickupTime',
}

type DispensariesQueryParams = {
  sortBy: SortBy;
  page: number;
  distance: number;
  recreational: boolean;
  medical: boolean;
  freeDelivery: boolean;
  openNow: boolean;
  offerCurbsidePickup: boolean;
  acceptsCreditCards: boolean;
  noMinimum: boolean;
};

type DispensaryQueryParams = {
  // TODO: Shape does not match return of useDispensariesQueryParams
  queryParams: any;
  defaults: DispensariesQueryParams;
  setQueryParams: (object) => void;
};

function useDistance(): number {
  const { queryParams } = useDispensariesQueryParams();
  const { distance, setDistance } = useSearchPreferences();

  useEffect(() => {
    setDistance(queryParams.distance);
  }, [setDistance, queryParams.distance]);

  return distance;
}

export const FiltersSidebarController = (): JSX.Element => {
  const { queryParams, defaults, setQueryParams }: DispensaryQueryParams = useDispensariesQueryParams();
  const { setOrderType: setOrderTypePreference, isPickup } = useSearchPreferences();
  const initialDistance = useDistance();

  const booleanFiltersArray = getBooleanFiltersArray(queryParams);

  // Distance Filter logic
  const [distance, setDistance] = useState(initialDistance);

  useEffect(() => {
    setDistance(initialDistance);
  }, [initialDistance]);

  function handleDistanceChangeCommitted(newValue: number): void {
    setQueryParams({ distance: newValue });
  }

  function handleDistanceChange(newValue: number): void {
    setDistance(newValue);
  }

  //  Order Type logic
  function handleOrderTypeSelect(orderType): void {
    const { openNow, acceptsCreditCards, recreational, medical } = queryParams;
    const pickupSelected = orderType === ORDER_TYPE_PICKUP;
    const defaultSortBy = isPickup ? SortBy.distance : SortBy.deliveryEstimate;
    const commonParams = { openNow, acceptsCreditCards, recreational, medical, sortBy: defaultSortBy };
    const paramsToReset = pickupSelected
      ? { freeDelivery: defaults.freeDelivery, noMinimum: defaults.noMinimum }
      : { distance: defaults.distance, offerCurbsidePickup: defaults.offerCurbsidePickup };
    setQueryParams({ ...commonParams, ...paramsToReset });
    setOrderTypePreference(orderType);
  }

  function toggleFilter(filter): void {
    setQueryParams({ [filter]: !queryParams[filter] });
  }

  return (
    <FiltersSidebar
      isPickup={isPickup}
      distance={distance}
      onOrderTypeSelect={handleOrderTypeSelect}
      onDistanceChange={handleDistanceChange}
      onDistanceChangeCommitted={handleDistanceChangeCommitted}
      toggleFilter={toggleFilter}
      booleanFiltersArray={booleanFiltersArray}
    />
  );
};

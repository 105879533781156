import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';

import { useSortDispensariesByOpenStatus } from 'hooks/use-sort-dispensaries-by-open-status';

import { DispensaryCard } from 'components/dispensary-card';

export default function DispensaryList({ dispensaries }) {
  const sortedDispensaries = useSortDispensariesByOpenStatus(dispensaries);

  return (
    <Container>
      {_.map(sortedDispensaries, (dispensary) => (
        <StyledListItem key={dispensary.id}>
          <DispensaryCard dispensary={dispensary} />
        </StyledListItem>
      ))}
    </Container>
  );
}

const StyledListItem = styled.li`
  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.blueGrey[90]};
  }
`;

const Container = styled.ul`
  list-style: none;
  margin: 0;
  width: 100%;
  padding: 0;
`;

import { useObserver } from 'mobx-react-lite';

import { useDispensarySearchQuery, GqlDispensarySearchQuery } from 'types/graphql';
import { WhenNotVoid } from 'shared/utils/type-utils';
import { useDispensariesQueryParams } from 'hooks/use-dispensaries-query-params';
import useCart from 'hooks/use-cart';

type Dispensaries = WhenNotVoid<GqlDispensarySearchQuery['filteredDispensaries']>;

type UseDispensariesReturn = {
  dispensaries: Dispensaries;
  loading: boolean;
};

type UseDispensariesProps = {
  useDefaultFilters?: boolean;
  isPickup: boolean;
};

export function useDispensaries({ useDefaultFilters = false, isPickup }: UseDispensariesProps): UseDispensariesReturn {
  const Cart = useCart();
  const address = useObserver(() => Cart?.currentAddress);

  const { queryParams, defaults: defaultQueryParams } = useDispensariesQueryParams();
  const queryParamsForFilter = useDefaultFilters ? defaultQueryParams : queryParams;

  const commonFilters = {
    medical: queryParamsForFilter.medical,
    recreational: queryParamsForFilter.recreational,
    sortBy: queryParamsForFilter.sortBy,
    activeOnly: true,
    city: address?.city,
    country: address?.countryLong,
    nearLat: address?.lat,
    nearLng: address?.lng,
    destinationTaxState: address?.state,
    destinationTaxZipcode: address?.zipcode,
  };

  const pickupFilters = {
    ...commonFilters,
    distance: queryParamsForFilter.distance,
    openNowForPickup: queryParamsForFilter.openNow,
    acceptsCreditCardsPickup: queryParamsForFilter.acceptsCreditCards,
    acceptsDutchiePay: queryParamsForFilter.acceptsDutchiePay,
    offerCurbsidePickup: queryParamsForFilter.offerCurbsidePickup,
    offerPickup: true,
  };

  const deliveryFilters = {
    ...commonFilters,
    noMinimum: queryParamsForFilter.noMinimum,
    freeDelivery: queryParamsForFilter.freeDelivery,
    openNowForDelivery: queryParamsForFilter.openNow,
    acceptsCreditCardsDelivery: queryParamsForFilter.acceptsCreditCards,
    acceptsDutchiePay: queryParamsForFilter.acceptsDutchiePay,
    openForDelivery: true,
    deliversToAddress: true,
    distance: 50,
  };
  const dispensaryFilter = isPickup ? pickupFilters : deliveryFilters;

  const { data, loading } = useDispensarySearchQuery({
    fetchPolicy: `cache-and-network`,
    skip: !address?.lat || !address?.lng,
    variables: { dispensaryFilter },
    notifyOnNetworkStatusChange: true,
  });

  const dispensaries = data?.filteredDispensaries ?? [];

  return { dispensaries, loading };
}

import React from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import useTranslation from 'hooks/use-translation';

import { Select, Option } from 'components/core';
import { DELIVERY_SORT_OPTIONS, PICKUP_SORT_OPTIONS } from 'dispensaries/constants';

const useStyles = makeStyles(() => ({
  paper: {
    marginTop: 4,
  },
}));

export function DispensariesSort({ displaySortLabel, onChange, isPickup, currentSortByKey }) {
  const { t, Trans } = useTranslation();
  const classes = useStyles();

  const sortOptions = isPickup ? PICKUP_SORT_OPTIONS : DELIVERY_SORT_OPTIONS;

  return (
    <StyledSelect
      inputProps={{ 'aria-label': t('common.menuSort', 'Menu Sort') }}
      MenuProps={{ classes: { paper: classes.paper } }}
      onChange={onChange}
      value={currentSortByKey}
      renderValue={(value) => (
        <Trans i18nKey='common.sortBy'>
          {displaySortLabel && `Sort:`} <b>{_.find(sortOptions, { key: value }, '')?.label}</b>
        </Trans>
      )}
    >
      {_.map(sortOptions, (option) => (
        <Option key={option.key} value={option.key}>
          {option.label}
        </Option>
      ))}
    </StyledSelect>
  );
}

const StyledSelect = styled(Select)`
  height: 34px;
  fieldset {
    display: none;
  }
  b {
    margin-left: 5px;
  }
`;

export type SetQueryParamsArgType = {
  sortBy?: string;
  page?: number;
  distance?: number;
  recreational?: boolean;
  medical?: boolean;
  freeDelivery?: boolean;
  openNow?: boolean;
  offerCurbsidePickup?: boolean;
  acceptsCreditCards?: boolean;
  noMinimum?: boolean;
};

export type DispensariesQueryParamsType = {
  sortBy: string;
  page: number;
  distance: number;
  recreational: boolean;
  medical: boolean;
  freeDelivery: boolean;
  openNow: boolean;
  offerCurbsidePickup: boolean;
  acceptsCreditCards: boolean;
  noMinimum: boolean;
};

export type QueryParamsObjType = {
  queryParams: DispensariesQueryParamsType;
  defaults: DispensariesQueryParamsType;
  setQueryParams: (object: SetQueryParamsArgType) => void;
};

export type FilterQueryParams = {
  distance: number;
  recreational: boolean;
  medical: boolean;
  freeDelivery: boolean;
  openNow: boolean;
  offerCurbsidePickup: boolean;
  acceptsCreditCards: boolean;
  noMinimum: boolean;
};

export enum FilterTypes {
  distance = 'distance',
  recreational = 'recreational',
  medical = 'medical',
  freeDelivery = 'freeDelivery',
  openNow = 'openNow',
  offerCurbsidePickup = 'offerCurbsidePickup',
  acceptsCreditCards = 'acceptsCreditCards',
  noMinimum = 'noMinimum',
}

export enum OrderTypes {
  pickup = 'pickup',
  delivery = 'delivery',
}

import React from 'react';
import styled from 'styled-components';

import { CheckboxGroup } from 'components/core';
import { STORE_TYPE_OPTIONS } from 'dispensaries/constants';

type StoreTypeFilterProps = {
  onSelect: (filter: string) => void;
  selectedFilters: string[];
};

export function StoreTypeFilters({ onSelect, selectedFilters }: StoreTypeFilterProps): JSX.Element {
  return (
    <Container>
      <CheckboxGroup options={STORE_TYPE_OPTIONS} selectedOptions={selectedFilters} onSelect={onSelect} />
    </Container>
  );
}

const Container = styled.div`
  margin-top: 11.5px;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-top: 0px;
  }
`;

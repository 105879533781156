import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { MENU_TYPE_MED, MENU_TYPE_REC } from 'shared/constants';
import { getPersistedValue, setPersistedValue } from 'shared/utils/persisted-values';
import { getCityToDisplay } from 'shared/helpers/address';

import useTranslation from 'hooks/use-translation';
import useUI from 'hooks/use-ui';
import useCart from 'hooks/use-cart';
import useSearchPreferences from 'hooks/use-search-preferences';
import { useDispensariesQueryParams } from 'hooks/use-dispensaries-query-params';
import { useDispensaryFilterPillOptions } from 'hooks/use-filter-pill-options';
import { useDispensaries } from './hooks/use-dispensaries';

import { getDispensariesHeading } from './utils';

import Dispensaries from './dispensaries';

function useAddress({ location, doNotRedirectOnMissingAddress }) {
  const { showHome } = useUI();
  const { updateAddress } = useCart();

  const addressObj = location ?? getPersistedValue(`address`);

  const setUpAddress = useCallback(() => {
    if (location) {
      updateAddress({ location, residentialStatus: 'VERIFIED' });
    }
  }, [updateAddress, location]);

  const redirectIfMissingAddress = useCallback(() => {
    if (!doNotRedirectOnMissingAddress && (!addressObj?.lat || !addressObj?.lng)) {
      showHome();
    }
  }, [addressObj?.lat, addressObj?.lng, showHome, doNotRedirectOnMissingAddress]);

  useEffect(setUpAddress, [setUpAddress]);
  useEffect(redirectIfMissingAddress, [redirectIfMissingAddress]);

  return { city: getCityToDisplay(addressObj), state: addressObj?.state, stateLong: addressObj?.stateLong };
}

function useMenuType() {
  const { setMenuType } = useCart();
  const {
    queryParams: { recreational, medical },
  } = useDispensariesQueryParams();

  useEffect(() => {
    if (recreational && !medical) {
      setMenuType(MENU_TYPE_REC);
    }
    if (medical && !recreational) {
      setMenuType(MENU_TYPE_MED);
    }
  }, [setMenuType, medical, recreational]);
}

export const DispensariesController = observer(({ location, doNotRedirectOnMissingAddress = false }) => {
  const { t } = useTranslation();
  const { isPickup } = useSearchPreferences();
  const { dispensaries, loading: dispensariesLoading } = useDispensaries({ isPickup });
  const filterPillOptions = useDispensaryFilterPillOptions({ isPickup });

  const { city, state, stateLong } = useAddress({ location, doNotRedirectOnMissingAddress });
  useMenuType();

  useEffect(() => {
    setPersistedValue(`dispensariesSearchParams`, '');
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const heading = getDispensariesHeading(t('common.dispensaries', 'Dispensaries'), city, state, stateLong);

  return (
    <Dispensaries
      dispensariesLoading={dispensariesLoading}
      dispensaries={dispensaries}
      filterPillOptions={filterPillOptions}
      heading={heading}
    />
  );
});

import React from 'react';

import { ORDER_TYPE_DELIVERY, ORDER_TYPE_PICKUP } from 'shared/constants';

import { CheckboxGroup } from 'components/core';
import { DISPENSARIES_FILTER_OPTIONS } from 'dispensaries/constants';

type DispensariesFiltersProps = {
  isPickup: boolean;
  selectedFilters: string[];
  onSelect: (filter: string) => void;
};

export function DispensariesFilters({ isPickup, selectedFilters, onSelect }: DispensariesFiltersProps): JSX.Element {
  const filterOptions = [...DISPENSARIES_FILTER_OPTIONS[isPickup ? ORDER_TYPE_PICKUP : ORDER_TYPE_DELIVERY]];

  return <CheckboxGroup options={filterOptions} selectedOptions={selectedFilters} onSelect={onSelect} />;
}

import useUI from 'hooks/use-ui';
import useTranslation from 'hooks/use-translation';
import { useDispensariesQueryParams } from 'hooks/use-dispensaries-query-params';
import { FiltersType } from 'components/filter-pills/types';
import {
  FilterOptionValues,
  PickupFilterOptionValues,
  DeliveryFilterOptionValues,
} from 'components/modals/dispensary-list-filters-bottom-drawer/types';

import { useModals, ModalNames } from 'components/modals';

type FilterPillOptionsProps = {
  isPickup: boolean;
};

export function useDispensaryFilterPillOptions({ isPickup }: FilterPillOptionsProps): FiltersType {
  const UI = useUI();
  const { t } = useTranslation();
  const { openModal } = useModals();
  const { queryParams, queryParamsMinusDefaults } = useDispensariesQueryParams();

  const orderTypeCopy = isPickup ? t('filters.pickup', 'Pickup') : t('filters.delivery', 'Delivery');

  const nonDefaultsQueryParams = new Set(Object.keys(queryParamsMinusDefaults));
  const generalFilters = Object.values(isPickup ? PickupFilterOptionValues : DeliveryFilterOptionValues);
  const generalFiltersActiveLength = generalFilters.filter((key: FilterOptionValues) => nonDefaultsQueryParams.has(key))
    .length;

  const storeTypeSelectedCopy = queryParams.medical
    ? t('storeType.medical', 'Medical')
    : t('storeType.recreational', 'Recreational');

  const filterOpts = [
    {
      active: queryParams.pickup || queryParams.delivery,
      activeCopy: orderTypeCopy,
      label: orderTypeCopy,
      onClick: () => {
        UI.openOrderTypeDrawer();
      },
    },
    {
      active: queryParams.medical || queryParams.recreational,
      activeCopy: storeTypeSelectedCopy,
      label: t('filters.bothStoreTypes', 'Store Types'),
      onClick: () => {
        UI.openStoreTypeDrawer();
      },
    },
    {
      active: generalFiltersActiveLength > 0,
      activeCopy: t('filters.generalActive', 'Filters ({{numberOfActiveFilters}})', {
        numberOfActiveFilters: generalFiltersActiveLength,
      }),
      label: t('filters.filters', 'Filters'),
      onClick: () => {
        UI.openDispensariesFiltersDrawer();
      },
    },
  ];

  const distanceFilter = {
    active: queryParams.distActive,
    activeCopy: t('filters.distanceActive', 'Within {{distance}} Miles', {
      distance: queryParams.distance,
    }),
    label: t('filters.distance', 'Distance'),
    onClick: () => openModal(ModalNames.consumerDistanceFilter),
  };

  if (isPickup) {
    filterOpts.splice(1, 0, distanceFilter);
  }

  return filterOpts;
}

import React from 'react';

import useTranslation from 'hooks/use-translation';

import Section from 'components/sidebar-filters/section';
import { TwoColumnSidebar } from 'components/layouts/two-column';
import { OrderTypeFilter, DispensariesFilters, DistanceFilter, StoreTypeFilters } from './filters';

type FiltersSidebarProps = {
  isPickup: boolean;
  distance: number;
  onOrderTypeSelect: (orderType: string) => void;
  toggleFilter: (filter: string) => void;
  onDistanceChange: (distance: number) => void;
  onDistanceChangeCommitted: (distance: number) => void;
  booleanFiltersArray: string[];
};

export function FiltersSidebar({
  isPickup,
  toggleFilter,
  onOrderTypeSelect,
  onDistanceChange,
  onDistanceChangeCommitted,
  booleanFiltersArray,
  distance,
}: FiltersSidebarProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <TwoColumnSidebar>
      <Section addPaddingRight startsOpen collapsible={false} heading={t(`common.orderType`, `Order Type`)}>
        <OrderTypeFilter onSelect={(orderType) => onOrderTypeSelect(orderType)} isPickup={isPickup} />
      </Section>

      <Section startsOpen collapsible={false} heading={t(`common.storeTypes`, `Store Types`)}>
        <StoreTypeFilters onSelect={toggleFilter} selectedFilters={booleanFiltersArray} />
      </Section>

      <Section startsOpen collapsible={false} heading={t(`common.filters`, `Filters`)}>
        <DispensariesFilters isPickup={isPickup} onSelect={toggleFilter} selectedFilters={booleanFiltersArray} />
      </Section>

      {isPickup && (
        <Section addPaddingRight startsOpen collapsible={false} heading={t(`common.distance`, `Distance`)}>
          <DistanceFilter value={distance} onChange={onDistanceChange} onChangeCommitted={onDistanceChangeCommitted} />
        </Section>
      )}
    </TwoColumnSidebar>
  );
}

import styled, { css } from 'styled-components';
import React from 'react';

import { Button } from 'components/core';
import SvgChevronIcon from 'src/assets/chevron-icon';
import { FiltersType } from './types';

export type FilterPillProps = FiltersType[number];

export function FilterPill({ active, activeCopy, label, onClick }: FilterPillProps): JSX.Element {
  return (
    <Pill active={active} data-testid={label} onClick={onClick}>
      {active ? activeCopy : label}
      <SvgChevronIcon active={active} height={5} width={8} />
    </Pill>
  );
}

const Pill = styled(Button)`
  ${({ theme: { colors }, active }) => css`
    text-transform: none;
    white-space: nowrap;
    font-size: 12px;
    line-height: 0px;
    height: 34px;
    padding: 11px 14px;
    color: ${active ? colors.white[100] : colors.grey[30]};
    background-color: ${active ? colors.grey[35] : colors.blueGrey[95]};
    svg {
      margin-right: unset;
      margin-left: 10px;
      fill: currentColor;
    }
  `}
`;

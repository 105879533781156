import { defaults as defaultQueryParams } from 'hooks/use-dispensaries-query-params';
import { FilterTypes, FilterQueryParams, DispensariesQueryParamsType } from './types';

function queryParamIsDefault(value: boolean | number, key: string, defaults: FilterQueryParams): boolean {
  return defaults[key] === value;
}

export function getDispensariesSearchFilterCount(queryParams: FilterQueryParams): number {
  return Object.entries(queryParams).reduce((filterCount, currentEntry) => {
    const [key, value] = currentEntry;
    if (!queryParamIsDefault(value, key, defaultQueryParams) && Object.keys(FilterTypes).includes(key)) {
      return filterCount + 1;
    }
    return filterCount;
  }, 0);
}

export function getBooleanFiltersArray(filtersObj: DispensariesQueryParamsType): string[] {
  return Object.keys(filtersObj).filter((key) => filtersObj[key] === true);
}

export function getDispensariesHeading(tDispensaries: string, city = '', state = '', stateLong = ''): string {
  const formattedCity = city && state ? `${city}, ` : '';
  const formattedState = city ? `${state} ` : `${stateLong} `;

  const heading = `${formattedCity}${formattedState}${tDispensaries}`;

  return heading.trim();
}

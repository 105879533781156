import React from 'react';
import useSearchPreferences from 'hooks/use-search-preferences';
import { useObserver } from 'mobx-react-lite';
import { useDispensariesQueryParams } from 'hooks/use-dispensaries-query-params';
import { DispensariesSort } from './dispensaries-sort';

export function DispensariesSortController({ displaySortLabel = true }) {
  const SearchPreferences = useSearchPreferences();
  const isPickup = useObserver(() => SearchPreferences.isPickup);
  const { queryParams, setQueryParams } = useDispensariesQueryParams();

  function onChange(e) {
    const newSort = e.target.value;
    setQueryParams({
      sortBy: newSort,
    });
  }

  return (
    <DispensariesSort
      displaySortLabel={displaySortLabel}
      isPickup={isPickup}
      onChange={onChange}
      currentSortByKey={queryParams.sortBy}
    />
  );
}

import React from 'react';
import styled from 'styled-components';

import useTranslation from 'hooks/use-translation';
import RangeSlider from 'components/core/range-slider';

type DistanceFilterProps = {
  value: number;
  onChange: (distance: number) => void;
  onChangeCommitted?: (distance: number) => void;
};

// eslint-disable-next-line max-len, lodash/prefer-constant
export function DistanceFilter({ value, onChange, onChangeCommitted }: DistanceFilterProps): JSX.Element {
  // TODO: This is a stop-gap solution so we don't introduce too many changes in this PR.
  // Come back and fix this "the right way" by converting `useTranslation` to TS.
  // https://app.clubhouse.io/dutchie/story/74064/convert-usetranslation-hook-to-ts
  const { Trans }: any = useTranslation();

  const pluralizedMileString = value === 1 ? `mile` : `miles`;

  return (
    <>
      <StyledRangeSlider
        aria-labelledby='distance-text'
        getAriaLabel={() => `Filter dispensaries by distance`}
        getAriaValueText={() => `Filter dispensaries by distance`}
        max={50}
        min={1}
        onChange={(_e, newValue: number) => onChange(newValue)}
        onChangeCommitted={(_e, newValue: number) => onChangeCommitted?.(newValue)}
        value={value}
      />
      <SliderLabel>
        <Trans i18nKey='dispensaries.distance-filter'>
          Show dispensaries within{' '}
          <SliderLabelType>
            {{ value }} {{ pluralizedMileString }}
          </SliderLabelType>
          of your location.
        </Trans>
      </SliderLabel>
    </>
  );
}

const StyledRangeSlider = styled(RangeSlider)`
  margin: 14px 0 0;

  ${({ theme }) => theme.breakpoints.down(`sm`)} {
    &&& {
      width: 80%;
      .MuiSlider-rail {
        height: 12px;
      }
      .MuiSlider-track {
        height: 12px;
      }
      .MuiSlider-thumb {
        height: 25px;
        width: 25px;
        margin-top: -7px;
      }
    }
  }
`;

const SliderLabel = styled.div`
  font-size: 12px;
  line-height: 19px;
  padding: 0 15px 0 0;
  margin-top: 6px;
`;

const SliderLabelType = styled.b`
  margin-right: 4px;
`;

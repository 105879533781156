import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import { LoadingSkeleton } from 'components/core';

export function DispensariesLoadingState() {
  return (
    <Container>
      {_.map(_.range(0, 6), (n) => (
        <DispensaryCardLoadingSkeleton key={n} />
      ))}
    </Container>
  );
}

export function DispensaryCardLoadingSkeleton() {
  return (
    <DispensaryCardContainer>
      <ImageLoadingSkeleton />
      <DispensaryCardDetailsContainer>
        <LoadingSkeleton width={135} />
        <LoadingSkeleton width={250} />
        <LoadingSkeleton width={135} />
      </DispensaryCardDetailsContainer>
    </DispensaryCardContainer>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ImageLoadingSkeleton = styled(LoadingSkeleton)`
  height: 107px;
  padding-bottom: 52%;
  ${({ theme }) => theme.breakpoints.down(`xs`)} {
    height: 182px;
  }
`;

const DispensaryCardContainer = styled.div`
  height: 160px;
  padding: 25px 0 27px;
  display: grid;
  grid-gap: 34px;
  grid-template-columns: 187px auto;
  border-bottom: ${({ theme }) => `solid 1px ${theme.colors.blueGrey[90]}`};
  ${({ theme }) => theme.breakpoints.down(`xs`)} {
    height: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: max-content auto;
    grid-gap: 14px;
    padding: 22px 0 26px 0;
  }
`;

const DispensaryCardDetailsContainer = styled.div`
  display: grid;
  grid-template-rows: 14px 10px 8px;
  grid-gap: 16px;
  padding: 22px 0;
  ${({ theme }) => theme.breakpoints.down(`xs`)} {
    grid-gap: 10px;
    grid-template-rows: repeat(3, 15px);
    padding: 0;
  }
`;

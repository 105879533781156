/* eslint-disable max-len */
import React from 'react';

export default function DispensariesEmptyState(props) {
  return (
    <svg width='278' height='186' fill='none' {...props} xmlns='http://www.w3.org/2000/svg'>
      <path
        opacity='.4'
        d='M253.098 100.991v35.653h-7.718V45.9054l-35.496-19.0667v31.6132H197.54V36.3624h-30.867V24.3681h8.614c1.231-.0218 2.213-.9567 2.189-2.0902v-6.3435c.024-1.1335-.958-2.0685-2.189-2.0903h-8.614L155.386.418457 143.524 13.8441h-10.156c-1.233.0218-2.213.9568-2.191 2.0903v6.341c-.022 1.1336.958 2.0685 2.191 2.0903h10.156v29.6707h-21.606V40.9086h6.174V30.5953H30.8663v10.3133h7.7147v85.6384l-10.8021-.243V97.7286L0 109.793v58.053h277.789v-67.044l-24.691.189z'
        fill='#B0C4CC'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M171.303 42.9529c-5.965 0-10.802 4.7691-10.802 10.6548l21.605.0024c0-5.8857-4.837-10.6572-10.803-10.6572zm-62.658 4.0037H54.939v10.6572h53.706V46.9566zM7.71484 117.534H21.6043v7.61H7.71484v-7.61zm13.88946 15.22H7.71484v7.611H21.6043v-7.611zM7.71484 147.975H21.6043v7.61H7.71484v-7.61zM223.774 50.5631h-6.172v13.6994h6.172V50.5631zm6.172 0h6.175v13.6994h-6.172l-.003-13.6994zm-6.172 22.8329h-6.172v13.697h6.172V73.396zm6.172-3.0468h6.175v13.6994h-6.172l-.003-13.6994zm-6.172 16.8286h-6.172v13.6972h6.172V87.1778zm6.172 2.9598h6.175v13.6994h-6.172l-.003-13.6994zm-6.172 16.8244h-6.172v13.699h6.172v-13.699zm6.172 2.962h6.175v13.699h-6.172l-.003-13.699zm41.671-1.439H259.27v7.61h12.347v-7.61zm-12.347 15.22h12.347v7.611H259.27v-7.611zm12.347 15.221H259.27v7.61h12.347v-7.61zm-54.015-12.176h6.172v13.699h-6.172V126.75zm18.519 2.96h-6.175l.003 13.699h6.172V129.71z'
        fill='#fff'
      />
      <path
        d='M212.095 177.503H59.1107c-21.8333 0-32.1358 1.572-32.1358 3.5 0 1.93.96 4.396 25.9611 4.396l17.1181-.037v.037h92.8739v-.058l56.021.058c27.711 0 28.778-2.454 28.778-4.392 0-1.937-11.423-3.504-35.628-3.504h-.004z'
        fill='#BDCCCE'
      />
      <path
        d='M224.222 178.556V85.221c2.608-.3531 4.557-2.5581 4.574-5.1736v-3.8415l-14.864-16.4266H62.276L47.4119 76.2059v3.8415c.0147 2.9087 2.3954 5.2567 5.3218 5.2487l.7725 3.0445v90.2154'
        fill='#E4EAEC'
      />
      <path d='M54.0154 85.6521v5.2705l168.2156 2.3421v-7.6102l-168.2156-.0024z' fill='#A1ACAF' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M52.4705 183.069h56.3305v-.005c1.279 0 2.315-1.022 2.315-2.284 0-1.26-1.036-2.282-2.315-2.282H57.1003V84.8915c0-1.262-1.0356-2.2841-2.3149-2.2841-1.2769 0-2.3149 1.0246-2.3149 2.2841v98.1775zm116.5175 0h56.331V84.8938c0-1.2619-1.038-2.284-2.315-2.284-1.28 0-2.315 1.0245-2.315 2.284v93.6072h-51.701c-1.279 0-2.315 1.022-2.315 2.281.002.609.247 1.192.681 1.621.434.429 1.022.668 1.634.666z'
        fill='#788387'
      />
      <path
        d='M145.839 161.757c-.611.002-1.198-.237-1.633-.665-.434-.427-.679-1.009-.682-1.617V141.21c0-1.259 1.036-2.284 2.315-2.284 1.28 0 2.315 1.022 2.315 2.284v18.263c0 1.262-1.035 2.284-2.315 2.284z'
        fill='#A1ACAF'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M121.918 183.069h33.953v-63.932h-33.953v63.932zm29.323-4.568h-24.693v-54.798h24.693v54.798z'
        fill='#788387'
      />
      <path d='M124.233 112.29h29.321v12.176h-29.321V112.29z' fill='#fff' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M121.918 126.75h33.953v-16.744h-33.953v16.744zm29.323-4.566h-24.693v-7.612h24.693v7.612z'
        fill='#788387'
      />
      <path d='M77.9346 109.248h24.6914v56.314H77.9346v-56.314z' fill='#EAF0F2' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M75.6196 167.846h29.3234v-60.882H75.6196v60.882zm24.6944-4.566H80.2494v-51.75h20.0646v51.75z'
        fill='#788387'
      />
      <path d='M54.7854 109.248h23.1491v56.314H54.7854v-56.314z' fill='#EAF0F2' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M52.4705 167.846h27.7789v-60.882H52.4705v60.882zm23.1491-4.566H57.1003v-51.75h18.5193v51.75z'
        fill='#788387'
      />
      <path d='M199.855 109.248h23.149v56.314h-23.149v-56.314z' fill='#EAF0F2' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M197.54 167.846h27.779v-60.882H197.54v60.882zm23.149-4.566H202.17v-51.75h18.519v51.75z'
        fill='#788387'
      />
      <path d='M175.161 109.248h24.691v56.314h-24.691v-56.314z' fill='#EAF0F2' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M172.846 167.846h29.324v-60.882h-29.324v60.882zm24.694-4.566h-20.064v-51.75h20.064v51.75z'
        fill='#788387'
      />
      <path
        d='M131.784 106.201l-7.551-5.542v-5.6578l7.551-5.5417h14.221l7.551 5.5417v5.6578l-7.551 5.542h-14.221z'
        fill='#9CAAB4'
      />
      <path
        d='M54.7854 103.159h50.9276v4.566c-.001.405-.164.793-.454 1.078-.289.285-.68.445-1.088.443H54.7854v-6.087z'
        fill='#788387'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M52.4705 111.53h51.7005v-.003c2.13 0 3.857-1.702 3.857-3.802v-6.85H52.4705v10.655zm50.9275-4.566H57.1003v-1.523h46.2977v1.523z'
        fill='#788387'
      />
      <path
        d='M172.076 103.159h50.928v6.087h-49.386c-.407.002-.799-.158-1.088-.443-.29-.285-.453-.673-.454-1.078v-4.566z'
        fill='#788387'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M173.618 111.53h51.701v-10.655h-55.558v6.85c.004 1.013.412 1.983 1.136 2.696.723.714 1.702 1.113 2.721 1.109zm47.071-4.566h-46.298v-1.523h46.298v1.523z'
        fill='#788387'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M65.9822 121.424c11.9401-9.134 4.5202-10.655-8.9063 1.521l.0244 22.07c0 4.566 7.3858.761 16.3457-6.85 4.3302-3.68 4.4727-3.089 4.8149-1.669.3654 1.516.9588 3.977 7.1252 3.191 11.9425-1.522 14.9279-6.087 14.9279-6.087v-6.089s-20.9199 7.61-11.9551-1.521c8.9623-9.134 7.4686-12.179-13.4265 1.521-19.8351 13.004-19.9521 2.325-8.9502-6.087zm122.2978 2.977c4.63-5.196 2.622-8.964-10.804-1.732v16.146c0 2.712 8.923.45 17.88-4.072 4.195-2.116 4.351-.143 4.556 2.46.233 2.957.531 6.727 6.88 6.245 6.711-.278 12.403-4.955 13.89-11.415v-3.616s-19.351 4.522-10.398-.904c7.871-4.764 4.233-8.802-13.427.904-14.752 8.106-17.683 6.205-8.577-4.016z'
        fill='#fff'
      />
      <path
        d='M106.639 154.149l8.992-9.134h-8.548l6.496-7.61h-7.471l-4.252-7.61-4.2448 7.61h-7.4711l6.4964 7.61h-8.5481l8.9916 9.131H86.2925l12.9878 11.811c1.4737 1.29 3.6827 1.29 5.1557 0l12.988-11.811h-10.782l-.003.003z'
        fill='#9CAAB4'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M182.88 167.088h-18.524v13.692h18.524v-13.692zm-72.534 0H91.8215v12.168h18.5245v-12.168z'
        fill='#A1ACAF'
      />
      <path
        d='M164.356 167.088v-.417h-.416v.417h.416zm18.524 0h.417v-.417h-.417v.417zm-18.524 13.692h-.416v.416h.416v-.416zm18.524 0v.416h.417v-.416h-.417zm-91.0585-13.692v-.417h-.4164v.417h.4164zm18.5245 0h.416v-.417h-.416v.417zm-18.5245 12.168h-.4164v.417h.4164v-.417zm18.5245 0v.417h.416v-.417h-.416zm54.01-11.752h18.524v-.833h-18.524v.833zm.417 13.276v-13.692h-.833v13.692h.833zm18.107-.417h-18.524v.833h18.524v-.833zm-.416-13.275v13.692h.833v-13.692h-.833zm-90.6425.416h18.5245v-.833H91.8215v.833zm.4165 11.752v-12.168h-.8329v12.168h.8329zm18.108-.416H91.8215v.833h18.5245v-.833zm-.417-11.752v12.168h.833v-12.168h-.833z'
        fill='#979797'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M96.6415 183.069h9.6565v-.003c2.677.082 5.041-1.722 5.654-4.313l3.616-15.473H87.3721l3.5966 15.373c.5784 2.635 2.9603 4.489 5.6728 4.416zm-1.138-5.341l-2.3149-9.882h16.5604l-2.322 9.977c-.182.447-.638.723-1.121.678h-9.6572c-.519.043-.9991-.281-1.1453-.773z'
        fill='#788387'
      />
      <path
        d='M179.174 154.149l8.992-9.134h-8.549l6.494-7.61h-7.468l-4.252-7.61-4.248 7.61h-7.466l6.497 7.61h-8.551l8.992 9.131h-10.788l12.988 11.811c1.473 1.29 3.683 1.29 5.156 0l12.988-11.811h-10.785v.003z'
        fill='#9CAAB4'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M169.176 183.069h9.657v-.003c2.677.082 5.041-1.722 5.653-4.313l3.614-15.473h-28.193l3.594 15.373c.579 2.636 2.962 4.491 5.675 4.416zm-1.138-5.341l-2.315-9.882h16.568l-2.33 9.977c-.181.447-.637.723-1.121.678h-9.657c-.521.043-.999-.281-1.145-.773zM46.2981 77.2838L61.73 59.7793h152.784l15.432 17.5045v3.0567c-.019 2.9422-2.425 5.3168-5.385 5.314H51.6857c-2.961.0041-5.3688-2.3708-5.3876-5.314v-3.0567zm30.0026 89.8972c.4342.428 1.0219.667 1.6337.665 1.2793 0 2.3149-1.022 2.3149-2.284v-56.316c0-1.26-1.0356-2.282-2.3149-2.282-1.2769 0-2.3149 1.022-2.3149 2.284v56.314c.0019.608.247 1.19.6812 1.619z'
        fill='#788387'
      />
      <path d='M218.53 73.4785H57.7144l7.8706-9.1313h145.079l7.866 9.1313z' fill='#C8CFD1' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M202.17 106.964h-4.63v57.84h4.63v-57.84zM96.4538 183.069h84.8812v-.003c1.277 0 2.315-1.022 2.315-2.284 0-1.259-1.038-2.281-2.315-2.281H96.4538c-1.2768 0-2.3149 1.022-2.3149 2.281.0013.609.2461 1.192.6803 1.621.4343.429 1.0224.668 1.6346.666z'
        fill='#788387'
      />
    </svg>
  );
}

import React from 'react';
import styled from 'styled-components';
import useTranslation from 'hooks/use-translation';

import routes from 'src/routes';
import { GqlPopularDispensary } from 'types/graphql';

import { TimeIcon } from 'assets/time-icon';
import Imgix from 'shared/components/imgix';
import Link from 'components/core/link';
import { DispensaryOrderingDetails } from 'components/dispensary-ordering-details';
import { Clickable } from 'shared/components';
import { ARIA_ROLES } from 'shared/constants';

export type DispensaryCardCarouselProps = {
  dispensary: GqlPopularDispensary;
  saveQueryParams: () => void;
  isDispensaryOpen: boolean;
};

const DIMENSIONS = {
  mobile: 325,
  tablet: 325,
  desktop: 262,
};

export function DispensaryCarouselCard({
  dispensary,
  saveQueryParams,
  isDispensaryOpen,
}: DispensaryCardCarouselProps): JSX.Element {
  const { t } = useTranslation();
  const { cName, name, listImage } = dispensary;
  const { DISPENSARY, DISPENSARY_CNAME } = routes;

  return (
    <Link href={`${DISPENSARY}/${String(cName)}`} route={DISPENSARY_CNAME}>
      {/* eslint-disable-next-line styled-components-a11y/anchor-is-valid */}
      <Container dimensions={DIMENSIONS} role={ARIA_ROLES.BUTTON}>
        <Clickable onClick={saveQueryParams}>
          <ImageContainer>
            <Image src={listImage} htmlAttributes={{ alt: name }} height={182} width={325} />
            {!isDispensaryOpen && (
              <StoreClosedOverlay>
                <StyledTimeIcon alt='hours' />
                <StoreClosedSpan>{t('common.closed', 'Closed')}</StoreClosedSpan>
              </StoreClosedOverlay>
            )}
          </ImageContainer>
          <DispensaryOrderingDetails dispensary={dispensary} variant='small' />
        </Clickable>
      </Container>
    </Link>
  );
}

const Container = styled.a`
  cursor: pointer;
  padding: 19px 0 27px;
  display: flex;
  min-width: ${({ dimensions }) => `${String(dimensions.desktop)}px`};
  margin: 0 46px 0 0;
  p {
    margin-top: 19px;
  }
  ${({ theme }) => theme.breakpoints.down(`xs`)} {
    padding: 22px 0 26px 0;
    min-width: ${({ dimensions }) => `${String(dimensions.mobile)}px`};
  }
`;

const ImageContainer = styled.div`
  position: relative;
`;

const Image = styled(Imgix)`
  border-radius: 11px;
  width: 100%;
  height: auto;
`;

const StoreClosedOverlay = styled.div`
  position: absolute;
  border-radius: 11px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.darkGreyOverlay};
  display: flex;
  flex-direction: column;
  place-content: center;
  place-items: center;
`;

const StyledTimeIcon = styled(TimeIcon)`
  margin-bottom: 8px;
  ${({ theme }) => theme.breakpoints.down(`xs`)} {
    width: 36px;
    height: 36px;
    margin-bottom: 10px;
  }
`;

const StoreClosedSpan = styled.span`
  width: 100%;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.blueGrey[80]};
  text-align: center;
  ${({ theme }) => theme.breakpoints.down(`xs`)} {
    font-size: 16px;
  }
`;

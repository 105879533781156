/* eslint-disable max-len */
import React from 'react';

export function TimeIcon(props) {
  return (
    <svg width='26' height='27' viewBox='0 0 26 27' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.5758 11.8649H18.1202C18.9884 11.8649 19.6942 12.5751 19.6942 13.4418C19.6942 14.3084 19.0271 15.0166 18.1202 15.0166H12.9999C12.1336 15.0166 11.424 14.3084 11.424 13.4418V6.97989C11.424 6.11322 12.1336 5.40504 12.9999 5.40504C13.8662 5.40504 14.5758 6.11322 14.5758 6.97989V11.8649ZM13.0002 22.935C18.4357 22.935 22.8483 18.5234 22.8483 13.0867C22.8483 7.64997 18.4357 3.23839 13.0002 3.23839C7.56468 3.23839 3.15206 7.64997 3.15206 13.0867C3.15206 18.5234 7.56468 22.935 13.0002 22.935ZM13 26.0867C5.82999 26.0867 0 20.2567 0 13.0867C0 5.91661 5.82999 0.0866699 13 0.0866699C20.17 0.0866699 26 5.91661 26 13.0867C26 20.2567 20.17 26.0867 13 26.0867Z'
        fill='#BCCAD2'
      />
    </svg>
  );
}

import React from 'react';
import _ from 'lodash';

import { useDispensariesQueryParams } from 'hooks/use-dispensaries-query-params';
import { setPersistedValue } from 'shared/utils/persisted-values';
import { openInfoForDispensary } from 'shared/core/helpers/dispensaries';

import { DispensaryCard } from './dispensary-card';
import { DispensaryCarouselCard } from './dispensary-carousel-card';

export function DispensaryCardController({ dispensary, isCarousel = false }) {
  const { queryParamsMinusDefaults } = useDispensariesQueryParams();
  const openInfo = openInfoForDispensary(dispensary);
  const isDispensaryOpen = _.some(openInfo, `isOpen`);

  function saveQueryParams() {
    setPersistedValue(`dispensariesSearchParams`, queryParamsMinusDefaults);
  }

  return isCarousel ? (
    <DispensaryCarouselCard
      dispensary={dispensary}
      isDispensaryOpen={isDispensaryOpen}
      saveQueryParams={saveQueryParams}
    />
  ) : (
    <DispensaryCard dispensary={dispensary} isOpen={isDispensaryOpen} saveQueryParams={saveQueryParams} />
  );
}

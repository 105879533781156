import React from 'react';
import styled from 'styled-components';

import { heading2, heading3, heading5 } from 'components/core/typography';
import { Button, TextButton } from 'components/core';
import DispensaryEmptyStateIcon from 'assets/dispensaries-empty-state';
import useTranslation from 'src/hooks/use-translation';
import { ORDER_TYPE_PICKUP, ORDER_TYPE_DELIVERY } from 'shared/constants';

export default function DispensariesEmptyState({
  isPickup,
  numDispensariesDelivery,
  numDispensariesPickup,
  setOrderType,
  openAddressModal,
  resetFilters,
}) {
  const { t, Trans } = useTranslation();

  const noDispensariesAvailable = !numDispensariesDelivery && !numDispensariesPickup;
  const orderType = isPickup ? ORDER_TYPE_PICKUP : ORDER_TYPE_DELIVERY;
  const otherOrderType = isPickup ? ORDER_TYPE_DELIVERY : ORDER_TYPE_PICKUP;
  const numDispensariesForOrderType = isPickup ? numDispensariesPickup : numDispensariesDelivery;
  const numDispensariesForOtherOrderType = isPickup ? numDispensariesDelivery : numDispensariesPickup;

  let heading;
  if (numDispensariesForOrderType > 0) {
    heading = {
      translationKey: `dispensariesPage.noDispensariesMatchFilters`,
      value: `There are no dispensaries that match those filters.`,
    };
  } else if (isPickup) {
    heading = {
      translationKey: `dispensariesPage.pickupUnavailable`,
      value: `There are no dispensaries available for pickup nearby!`,
    };
  } else {
    heading = {
      translationKey: `dispensariesPage.deliveryUnavailable`,
      value: `There are no dispensaries that deliver to your location!`,
    };
  }

  function ResetCopy() {
    return numDispensariesForOrderType === 1 ? (
      <p>
        <Trans i18nKey='dispensariesPage.resetFiltersToViewAll'>
          Reset filters to view <b>1 dispensary</b> available for {{ orderType }}.
        </Trans>
      </p>
    ) : (
      <p>
        <Trans i18nKey='dispensariesPage.resetFiltersToViewAll'>
          Reset filters to view all <b>{{ numDispensariesForOrderType }} dispensaries</b> available for {{ orderType }}.
        </Trans>
      </p>
    );
  }

  function OtherOrderTypeCopy() {
    return numDispensariesForOtherOrderType === 1 ? (
      <p>
        <Trans i18nKey='dispensariesPage.dispensariesOfferOtherOrderType'>
          Good news though, there is <b>1 dispensary</b> nearby that offers {{ otherOrderType }}.
        </Trans>
      </p>
    ) : (
      <p>
        <Trans i18nKey='dispensariesPage.dispensariesOfferOtherOrderType'>
          Good news though, there are <b>{{ numDispensariesForOtherOrderType }} dispensaries</b> nearby that offer{' '}
          {{ otherOrderType }}.
        </Trans>
      </p>
    );
  }

  return (
    <Container>
      <DispensaryEmptyStateIcon />
      <Header>{t(heading.translationKey, heading.value)}</Header>
      {/* NO DISPENSARIES AVAILABLE FOR ADDRESS/DISTANCE */}
      {noDispensariesAvailable && (
        <>
          <Text>{t('dispensariesPage.tryChangingAddress', 'Try changing your address to return more results.')}</Text>
          <Button onClick={openAddressModal}>{t('dispensariesPage.changeAddress', 'Change Address')}</Button>
        </>
      )}

      {/* DISPENSARIES AVAILABLE FOR CURRENT ORDER TYPE IF FILTERS ARE REMOVED */}
      {numDispensariesForOrderType > 0 && (
        <>
          <Text>
            <ResetCopy />
          </Text>
          <Button onClick={resetFilters}>{t('dispensariesPage.resetFilters', 'Reset Filters')}</Button>
          <TryDifferentAddressLink onClick={openAddressModal}>
            {t('dispensariesPage.tryDifferentAddress', 'Try a different address')}
          </TryDifferentAddressLink>
        </>
      )}

      {/* NO DISPENSARIES AVAILABLE FOR CURRENT ORDER TYPE BUT THERE ARE SOME FOR THE OTHER */}
      {numDispensariesForOrderType === 0 && numDispensariesForOtherOrderType > 0 && (
        <>
          <Text>
            <OtherOrderTypeCopy />
          </Text>
          <Button
            onClick={() => {
              setOrderType(otherOrderType);
            }}
          >
            {t('dispensariesPage.switchOrderType', { defaultValue: 'Switch to {{otherOrderType}}', otherOrderType })}
          </Button>
          <TryDifferentAddressLink onClick={openAddressModal}>
            {t('dispensariesPage.tryDifferentAddress', 'Try a different address')}
          </TryDifferentAddressLink>
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: grid;
  place-items: center;
  grid-gap: 23px;
  padding: 77px 0 250px;
  ${({ theme }) => theme.breakpoints.down(`xs`)} {
    padding: 55px 0 128px;
  }
`;

const Header = styled.h2`
  ${heading2}
  margin-top: 24px;
  color: ${({ theme }) => theme.colors.primaryBlack};
  text-align: center;
  ${({ theme }) => theme.breakpoints.down(`xs`)} {
    ${heading3}
  }
`;

const Text = styled.p`
  ${heading5}
  text-align: center;
  line-height: 1.5;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.grey[35]};
`;

const TryDifferentAddressLink = styled(TextButton)`
  ${heading5}
`;

import React from 'react';
import styled from 'styled-components';
import useTranslation from 'hooks/use-translation';

import routes from 'src/routes';
import Imgix from 'shared/components/imgix';
import Link from 'components/core/link';
import { TimeIcon } from 'assets/time-icon';
import { DispensaryOrderingDetails } from 'components/dispensary-ordering-details';

export function DispensaryCard({ dispensary, saveQueryParams, isOpen }) {
  const { t } = useTranslation();
  const { name, listImage } = dispensary;
  return (
    <Link href={`${routes.DISPENSARY}/${dispensary.cName}`} route={routes.DISPENSARY_CNAME}>
      <Container onClick={saveQueryParams} data-testid='dispensary-card'>
        <ImageContainer>
          <Image src={listImage} htmlAttributes={{ alt: name }} height={182} width={325} />
          {!isOpen && (
            <StoreClosedOverlay>
              <StyledTimeIcon alt='hours' />
              <StoreClosedSpan>{t('common.closed', 'Closed')}</StoreClosedSpan>
            </StoreClosedOverlay>
          )}
        </ImageContainer>
        <DispensaryOrderingDetails dispensary={dispensary} />
      </Container>
    </Link>
  );
}

const Container = styled.a`
  background: transparent;
  border: none;
  text-align: left;
  width: 100%;
  cursor: pointer;
  padding: 25px 0 27px;
  display: grid;
  grid-gap: 34px;
  grid-template-columns: 187px auto;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down(`xs`)} {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    grid-gap: 14px;
    padding: 22px 0 26px 0;
  }
`;

const ImageContainer = styled.div`
  position: relative;
`;

const Image = styled(Imgix)`
  border-radius: 11px;
  height: auto;
  max-height: 53vw;
  object-fit: contain;
  width: 100%;

  ${({ theme }) => theme.breakpoints.up(`sm`)} {
    max-height: 106px;
  }
`;

const StoreClosedOverlay = styled.div`
  position: absolute;
  border-radius: 11px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.darkGreyOverlay};
  display: flex;
  flex-direction: column;
  place-content: center;
  place-items: center;
`;

const StyledTimeIcon = styled(TimeIcon)`
  margin-bottom: 8px;

  ${({ theme }) => theme.breakpoints.down(`xs`)} {
    width: 36px;
    height: 36px;
    margin-bottom: 10px;
  }
`;

const StoreClosedSpan = styled.span`
  width: 100%;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.blueGrey[80]};
  text-align: center;

  ${({ theme }) => theme.breakpoints.down(`xs`)} {
    font-size: 16px;
  }
`;

import React from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';

import { FilterPill } from './filter-pill';
import { FiltersType } from './types';

export type FilterPillsProps = {
  filterPillOptions: FiltersType;
};

export function FilterPills({ filterPillOptions }: FilterPillsProps): JSX.Element {
  return (
    <Container>
      {filterPillOptions.map((filterOption) => (
        <FilterPill {...filterOption} key={filterOption.label} />
      ))}
    </Container>
  );
}

const Container = styled.div`
  button:not(:last-of-type) {
    margin-right: 10px;
  }
`;

export const FilterPillsOverflowScrollContainer = styled.div`
  margin-left: -25px;
  width: 100vw;
  ${space}
  div {
    display: flex;
    overflow-x: scroll;
    position: relative;
    width: 100%;
    padding-left: 25px;
    scrollbar-width: none;
    z-index: 2;
    &::after {
      content: '';
      padding-right: 25px;
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

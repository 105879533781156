import React from 'react';
import styled, { css } from 'styled-components';

import useTranslation from 'hooks/use-translation';

import { ORDER_TYPE_DELIVERY, ORDER_TYPE_PICKUP } from 'shared/constants';
import { Button } from 'components/core';

type OrderTypeFilterProps = {
  onSelect: (orderType: string) => void;
  isPickup: boolean;
};

export const OrderTypeFilter = ({ onSelect, isPickup }: OrderTypeFilterProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Container>
      <StyledButton onClick={() => onSelect(ORDER_TYPE_PICKUP)} value={ORDER_TYPE_PICKUP} selected={isPickup} noDim>
        {t(`common.pickup`, ORDER_TYPE_PICKUP)}
      </StyledButton>
      <StyledButton
        onClick={() => onSelect(ORDER_TYPE_DELIVERY)}
        value={ORDER_TYPE_DELIVERY}
        selected={!isPickup}
        noDim
      >
        {t(`common.delivery`, ORDER_TYPE_DELIVERY)}
      </StyledButton>
    </Container>
  );
};

const StyledButton = styled(Button)`
  width: 100%;
  text-transform: capitalize;
  line-height: 24.5px;
  padding: 4px 17px;
  background-color: ${({ theme }) => theme.colors.blueGrey[95]};
  color: ${({ theme }) => theme.colors.grey[60]};
  font-size: 14px;
  :last-of-type {
    margin-left: 5px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 9px 17px;
    :last-of-type {
      margin-left: 11px;
    }
  }

  ${({ selected }) =>
    selected &&
    css`
      background-color: ${({ theme }) => theme.colors.grey[35]};
      color: ${({ theme }) => theme.colors.white};
    `}
`;

const Container = styled.div`
  margin-top: 11.5px;
  display: flex;
  justify-content: space-between;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-top: 23px;
  }
`;

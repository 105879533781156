import React from 'react';
import { useObserver } from 'mobx-react-lite';

import useSearchPreferences from 'hooks/use-search-preferences';
import useUI from 'hooks/use-ui';
import { useDispensariesQueryParams } from 'hooks/use-dispensaries-query-params';
import { useDispensaries } from 'dispensaries/hooks/use-dispensaries';
import DispensariesEmptyState from './dispensaries-empty-state';

export function DispensariesEmptyStateController() {
  const SearchPreferences = useSearchPreferences();
  const UI = useUI();
  const isPickup = useObserver(() => SearchPreferences.isPickup);

  const { resetQueryParams } = useDispensariesQueryParams();

  const { dispensaries: deliveryDispensaries } = useDispensaries({ useDefaultFilters: true, isPickup: false });
  const { dispensaries: pickupDispensaries } = useDispensaries({ useDefaultFilters: true, isPickup: true });
  const numDispensariesDelivery = deliveryDispensaries.length;
  const numDispensariesPickup = pickupDispensaries.length;

  return (
    <DispensariesEmptyState
      numDispensariesDelivery={numDispensariesDelivery}
      numDispensariesPickup={numDispensariesPickup}
      setOrderType={SearchPreferences.setOrderType}
      openAddressModal={UI.toggleLocationSettingsDropdown}
      resetFilters={resetQueryParams}
      isPickup={isPickup}
    />
  );
}
